import { Link } from 'gatsby'
import React from 'react'
import Helmet from 'react-helmet'
import { Waypoint } from 'react-waypoint'
import pic01 from '../assets/images/scg.transparent.png'
import aballard from '../assets/images/a.ballard.jpg'
import aboard from '../assets/images/a.board.jpg'
import Header from '../components/Header'
import Layout from '../components/layout'
import Nav from '../components/Nav'

class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stickyNav: false,
    }
  }

  _handleWaypointEnter = () => {
    this.setState(() => ({ stickyNav: false }))
  }

  _handleWaypointLeave = () => {
    this.setState(() => ({ stickyNav: true }))
  }

  render() {
    const rightAlign = {
      marginRight: '4em',
    }
    return (
      <Layout>
        <Helmet title="Sage Counseling Group LLC" />

        <Header />

        <Waypoint
          onEnter={this._handleWaypointEnter}
          onLeave={this._handleWaypointLeave}
        ></Waypoint>
        <Nav sticky={this.state.stickyNav} />

        <div id="main">
          <section id="intro" className="main">
            <div className="spotlight">
              <div className="content">
                <header className="major">
                  <h2></h2>
                </header>
                <p>
                Sage Counseling Group is committed to the mental health and behavioral health wellness of individuals,
their families, and the community. Sage Counseling Group’s (SCG) vision is to educate, encourage and
empower our clients
                </p>
                <p>SCG has experience with many of life’s challenges.</p>
                <ul className="">
                  <li>ADHD</li>
                  <li>Anger</li>
                  <li>Anxiety</li>
                  <li>Depression</li>
                  <li>Emotional Abuse</li>
                  <li>Grief and Loss</li>
                  <li>Lack of Purpose</li>
                  <li>Marriage and Family</li>
                  <li>Mood Disorders</li>
                  <li>Parenting</li>
                  <li>Physical Abuse</li>
                  <li>PTSD</li>
                  <li>Self Injury</li>
                  <li>Sexual Abuse</li>
                  <li>Stress</li>
                  <li>Trauma</li>
                  <li>And Many More...</li>
                </ul>
                <p>SCG offers the following services:</p>
                <ul className="">
                  <li>Individual Therapy</li>
                  <li>Couples Counseling</li>
                  <li>Family Counseling</li>
                  <li>Grief Services</li>
                  <li>Online Counseling</li>
                </ul>
              </div>
              <span className="image">
                <img src={pic01} alt="" />
              </span>
            </div>
          </section>

          { <section id="about" className="main">
            <div className="spotlight">
              <span className="image" style={{ marginRight: '4em' }}>
                <img src={aboard} alt="" />
              </span>
              <div className="content">
                <header className="major">
                  <h2>Ameerah M. Board M.Ed, LPCC-S</h2>
                </header>
                <p>I am a Liscensed Professional Clinical Counselor with a Supervision Designation. One of my passions is assisting others with working towards becoming the best versions of themselves. I have worked with children, adolescents, and adults with various needs in a variety of settings. My recent work has utilized CBT (Cognitive Behavioral Therapy), including TF-CBT ( Trauma Focused).</p>
                <p>I am married with 2 wonderful children. When I am not working with my clients, I enjoy reading, music, sports and spending time with my family. </p>
                <ul className="actions">
                  <li></li>
                </ul>
              </div>
            </div>
            <div className="spotlight">
              <span className="image" style={{ marginRight: '4em' }}>
                <img src={aballard} alt="" />
              </span>
              <div className="content">
                <header className="major">
                  <h2>Arielle Ballard, LPCC-S</h2>
                </header>
                <p>My favorite part of therapy is witnessing my client's "aha" moments! My goal is to provide an environment for you that feels both professional and welcoming so that you feel comfortable enough to fully open up in sessions. With over 15 years of experience, my specialities include anxiety, depression, adjustment, trauma and anger management. I enjoy working with the adolescent and adult populations.  My work with clients is best described as person-centered. I want to meet you where you are and help you grow toward where you'd like to be. I utilize CBT, DBT and solution focused techniques. I treat a wide variety of clients with a focus on depression, anxiety, adjustment, trauma and anger.  I'm a listener but also an encourager. I hope you leave each session feeling empowered and validated.</p>
                <ul className="actions">
                  <li></li>
                </ul>
              </div>
            </div>            
          </section> }

          { <section id="insurance" className="main">
            <div className="spotlight">
              <div className="content" style={{ marginRight: '4em' }}>
                <header className="major">
                  <h2>Insurances Accepted</h2>
                </header>
                <ul className="">
                  <li>Oscar Health</li>
                  <li>United Healthcare</li>
                  <li>Oxford</li>
                  <li>Cigna</li>
                  <li>Anthem BCBS</li>
                  <li>Aetna</li>
                  <li>Optum EAP</li>
                  <li>Cigna EAP</li>
                  <li>Medical Mutual of OH</li>
                  <li>Caresource and Medicaid of OH</li>
                  <li>SCG also has Private Pay rates available</li>
                </ul>
              </div>
            </div>
          </section> }
        </div>
      </Layout>
    )
  }
}

export default Index
